import _ from 'lodash'
import { PlatformLogger, SessionServiceAPI, WixCodeBootstrapData } from '@wix/thunderbolt-symbols'
import { WixCodeAppDefId } from './constants'

declare let self: DedicatedWorkerGlobalScope & { elementorySupport: any }

export function elementorySupportScriptUrlFor(wixCodeBootstrapData: WixCodeBootstrapData) {
	return `${wixCodeBootstrapData.wixCodePlatformBaseUrl}/wixCodeNamespacesAndElementorySupport.min.js`
}

const createElementorySupportQueryParams = ({ codeAppId, wixCodeInstance, viewMode }: { codeAppId: string; wixCodeInstance: string; viewMode: string }) =>
	`?gridAppId=${codeAppId}&instance=${wixCodeInstance}&viewMode=${viewMode}`

export async function importAndInitElementorySupport({
	importScripts,
	wixCodeBootstrapData,
	sessionService,
	viewMode,
	csrfToken,
	externalBaseUrl,
	logger,
}: {
	importScripts: any
	wixCodeBootstrapData: WixCodeBootstrapData
	sessionService: SessionServiceAPI
	viewMode: string
	csrfToken: string
	externalBaseUrl: string
	logger: PlatformLogger
}) {
	if (!self.elementorySupport) {
		try {
			await importScripts(elementorySupportScriptUrlFor(wixCodeBootstrapData), 'wixCodeNamespacesAndElementorySupport')
		} catch {}
	}
	if (!self.elementorySupport) {
		const error = new Error('could not load elementorySupport')
		logger.captureError(error, { tags: { elementorySupportImport: true }, extra: { elementorySupportScriptUrl: elementorySupportScriptUrlFor(wixCodeBootstrapData) } })
		return
	}

	const options = { headers: { 'X-XSRF-TOKEN': csrfToken } }
	self.elementorySupport.baseUrl = `${externalBaseUrl}/_api/wix-code-public-dispatcher/siteview`
	self.elementorySupport.options = _.assign({}, self.elementorySupport.options, options)
	if (wixCodeBootstrapData.wixCodeModel) {
		self.elementorySupport.queryParameters = createElementorySupportQueryParams({
			codeAppId: wixCodeBootstrapData.wixCodeModel.appData.codeAppId,
			viewMode,
			wixCodeInstance: sessionService.getWixCodeInstance(),
		})
		sessionService.onInstanceChanged(({ instance: wixCodeInstance }) => {
			self.elementorySupport.queryParameters = createElementorySupportQueryParams({
				codeAppId: wixCodeBootstrapData.wixCodeModel.appData.codeAppId,
				viewMode,
				wixCodeInstance,
			})
		}, WixCodeAppDefId)
	}
}
