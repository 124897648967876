import { Connections, PlatformLogger, PlatformModel, SiteAssetsResourceType } from '@wix/thunderbolt-symbols'
import { MasterPageId } from './constants'
import { errorPagesIds } from '@wix/thunderbolt-commons'
import _ from 'lodash'
import { FeaturesResponse } from './types'
import { BootstrapData } from '../types'
import { SiteAssetsClientAdapter } from 'thunderbolt-site-assets-client'

export function getModelsFactory({ bootstrapData, logger, siteAssetsClient }: { bootstrapData: BootstrapData; logger: PlatformLogger; siteAssetsClient: SiteAssetsClientAdapter }) {
	const fetchModel = <T>(resourceType: SiteAssetsResourceType, isMasterPage: boolean): Promise<T> =>
		logger.runAsyncAndReport(`getModel_${resourceType}${isMasterPage ? `_${MasterPageId}` : ''}`, () => {
			const pageCompId = isMasterPage ? MasterPageId : `${bootstrapData.currentPageId}`
			const isErrorPage = !!errorPagesIds[pageCompId]
			const errorPageData = isErrorPage ? { pageCompId: isErrorPage ? 'masterPage' : pageCompId, errorPageId: pageCompId } : {}
			const {
				modulesParams,
				siteScopeParams,
				clientInitParams: { fallbackStrategy },
			} = bootstrapData.platformEnvData.siteAssets
			const pageJsonFileNames = siteScopeParams.pageJsonFileNames
			const pageJsonFileName = isMasterPage || isErrorPage ? pageJsonFileNames[MasterPageId] : pageJsonFileNames[pageCompId]
			// TODO - handle/catch site-assets client error
			logger.captureBreadcrumb({
				message: 'fetchModel',
				category: 'model',
				data: {
					moduleParams: modulesParams[resourceType],
					pageCompId,
					isErrorPage,
					errorPageData,
					pageJsonFileName,
					pageJsonFileNames,
					isMasterPage,
					'bootstrapData-pageJsonFileName': bootstrapData.platformEnvData.router.pageJsonFileName,
				},
			})
			return siteAssetsClient.execute(
				{
					moduleParams: modulesParams[resourceType],
					pageCompId,
					...errorPageData,
					pageJsonFileName: pageJsonFileName || bootstrapData.platformEnvData.router.pageJsonFileName,
				},
				fallbackStrategy
			)
		})

	function mergeConnections(masterPageConnections: Connections, pageConnections: Connections) {
		// merge connection arrays
		return _.mergeWith(pageConnections, masterPageConnections, (objValue, srcValue) => (_.isArray(objValue) ? objValue.concat(srcValue) : undefined))
	}

	const getModelFromSiteAssetsResponses = (isMasterPage: boolean, [platformModel, featuresModel]: [PlatformModel, FeaturesResponse]) => {
		const {
			props: pageConfig,
			structure: { components: structureModel },
		} = featuresModel
		const { connections, applications, orderedControllers, onLoadProperties, sosp } = platformModel

		return {
			pageConfig,
			propsModel: pageConfig.render.compProps,
			structureModel,
			rawMasterPageStructure: isMasterPage ? structureModel : {},
			platformModel: {
				connections,
				applications,
				orderedControllers,
				sdkData: platformModel.sdkData,
				staticEvents: platformModel.staticEvents,
				compIdConnections: platformModel.compIdConnections,
				containersChildrenIds: platformModel.containersChildrenIds,
				compIdToRepeaterId: platformModel.compIdToRepeaterId,
				onLoadProperties,
				sosp,
			},
		}
	}

	const fetchPageModel = (pageType: 'masterPage' | 'page') => {
		const isMasterPage = pageType === 'masterPage'
		return Promise.all([fetchModel<PlatformModel>('platform', isMasterPage), fetchModel<FeaturesResponse>('features', isMasterPage)])
			.then(addGhosts)
			.then((result) => getModelFromSiteAssetsResponses(isMasterPage, result))
	}

	const getModels = async () => {
		const pageModelPromise = fetchPageModel('page')
		if (bootstrapData.platformEnvData.site.isResponsive || bootstrapData.platformEnvData.bi.pageData.isLightbox) {
			return pageModelPromise
		}

		const masterPageModelPromise = fetchPageModel('masterPage')
		const [pageModel, masterPageModel] = await Promise.all([pageModelPromise, masterPageModelPromise])

		const applications = _.merge({}, masterPageModel.platformModel.applications, pageModel.platformModel.applications)
		const pageConfig = _.merge({}, masterPageModel.pageConfig, pageModel.pageConfig)
		const connections = mergeConnections(masterPageModel.platformModel.connections, pageModel.platformModel.connections)
		const onLoadProperties = _.merge({}, masterPageModel.platformModel.onLoadProperties, pageModel.platformModel.onLoadProperties)
		const structureModel = _.assign({}, masterPageModel.structureModel, pageModel.structureModel)
		const sdkData = _.assign({}, masterPageModel.platformModel.sdkData, pageModel.platformModel.sdkData)
		const staticEvents = _.concat(masterPageModel.platformModel.staticEvents, pageModel.platformModel.staticEvents)
		const compIdConnections = _.assign({}, masterPageModel.platformModel.compIdConnections, pageModel.platformModel.compIdConnections)
		const containersChildrenIds = _.assign({}, masterPageModel.platformModel.containersChildrenIds, pageModel.platformModel.containersChildrenIds)
		const compIdToRepeaterId = _.assign({}, masterPageModel.platformModel.compIdToRepeaterId, pageModel.platformModel.compIdToRepeaterId)
		const orderedControllers = masterPageModel.platformModel.orderedControllers.concat(pageModel.platformModel.orderedControllers)
		const propsModel = pageConfig.render.compProps

		return {
			pageConfig,
			propsModel,
			structureModel,
			rawMasterPageStructure: masterPageModel.rawMasterPageStructure,
			platformModel: {
				connections,
				applications,
				orderedControllers,
				sdkData,
				staticEvents,
				compIdConnections,
				containersChildrenIds,
				onLoadProperties,
				compIdToRepeaterId,
				sosp: masterPageModel.platformModel.sosp,
			},
		}
	}

	const addGhosts = ([platformModel, featuresModel]: [PlatformModel, FeaturesResponse]): [PlatformModel, FeaturesResponse] => {
		Object.assign(featuresModel.props.render.compProps, platformModel.ghosts.props)

		Object.assign(featuresModel.structure.components, platformModel.ghosts.structure)
		// add ghost structure component to its parent components list
		_.entries(platformModel.ghosts.parentComponentsUpdates).forEach(([id, components]) => {
			featuresModel.structure.components[id].components = components
		})
		return [platformModel, featuresModel]
	}

	return {
		getModels,
	}
}
