import _ from 'lodash'
import { BootstrapData } from '../types'
import { ModelsAPI } from './types'
import { getAPIsOverModel } from './modelsApi'

export default function ({ bootstrapData, handlers, getModels }: { bootstrapData: BootstrapData; handlers: any; getModels: Function }) {
	async function getModelApi(): Promise<ModelsAPI> {
		const model = await getModels()
		model.platformModel.orderedControllers = ['wixCode', ...model.platformModel.orderedControllers]

		const modelsApi = getAPIsOverModel(model, bootstrapData)

		if (!bootstrapData.platformEnvData.window.isSSR) {
			handlers.registerOnPropsChangedHandler(bootstrapData.currentContextId, (changes: { [compId: string]: any }) => {
				_.map(changes, (newProps: any, compId: string) => {
					modelsApi.updateProps(compId, newProps)
				})
			})
		}

		return modelsApi
	}

	return {
		getModelApi,
	}
}
